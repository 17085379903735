@import "../../../global.scss";

.company{
  padding: 15px;
  border-radius: 20px;
  background-color: #fafafa;
  border: solid 1px #eaeaea;
  transition: all 0.3s;
}

.company:hover{
  scale: 1.03;
  cursor: pointer;
  background-color: $primary-light;
  color: $secondary;
  border-color: $secondary;
}

.subscription__card{
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.2s;
  border: solid white 5px;
};

.subscription__card-selected{
  border: solid $secondary 5px;
}

.subscription__card:hover{
  cursor: pointer;
  scale: 1.03;
}

.verify__card{
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.2s;
  border: solid white 5px;
};

.verify__card:hover{
  cursor: pointer;
  scale: 1.03;
}


.companybank_top{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 200px;
  max-width: 570px;
  // width: 50%;
  padding: 20px 20px 30px 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;

  &-card{
    background-color: white;
    border-radius: 16px;
    padding: 20px;

  }
}