@import "../../../../global.scss";

.approval{
  background-color: #fbfbfb;
  border-radius: 16px;
  // min-width: 570px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: 20px;
  transition: .3s all;
}

.approval:hover{
  background-color: #fefefe;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.approval__box{
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  min-height: 150px;

  // padding: 10px;
}