@import "../../../../global.scss";

.party{
    width: 100%;
    background-color: white;
    border-radius: 16px;
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  
    &:hover{
      box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 8px;
    }
  }