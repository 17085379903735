@import "../../../../global.scss";

.subs__top{
  min-height: 50%;
}

.subs__bottom{
  min-height: 50%;
  padding: 20px;
}

.subs__addon-card{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 16px;
  padding: 20px;
  transition: all 0.2s;
}

.subs__addon-card:hover{
  cursor: pointer;
  scale: 1.03;
}


.subs__my-subs{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 570px;
  width: 50%;
  padding: 20px 20px 30px 20px;
  margin: auto;

  gap: 20px;

  margin-bottom: 40px;

  &-card{
    background-color: white;
    border-radius: 16px;
    padding: 20px;

  }
}