@import "../../../global.scss";

.approvals{
    // border-radius: 16px;
    overflow: hidden;
  }
  
  
  .approvals__act{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 12px;
    border-radius: 12px;
  }
  
  .approvals__multiselect{
    margin: 15px 0px 15px 0px;
    border-radius: 16px;
    background-color: #fafafa;
    padding: 10px 10px 10px 30px;
  }