@import "../../../../global.scss";

.company__top{
  background-color: #fbfbfb;
  border-radius: 16px;
  min-width: 500px;
  width: 50%;
  max-width: 55%;
  padding: 20px 20px 20px 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 20px;
}

.company__branch{
  background-color: #fbfbfb;
  padding: 15px;
  border-radius: 16px;
}