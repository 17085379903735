@import "../../../../global.scss";

.pmtmethod__top{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 570px;
  width: 50%;
  padding: 20px 20px 30px 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;

  &-card{
    background-color: white;
    border-radius: 16px;
    padding: 20px;

  }
}

.pmtmethod__bottom{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 570px;
  width: 50%;
  padding: 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;
}

.verify__card{
  padding: 20px;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.2s;
  border: solid white 5px;
};

.verify__card:hover{
  cursor: pointer;
  scale: 1.03;
}