@import "../../../../global.scss";

.recemethod__top{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 570px;
  width: 50%;
  padding: 20px 20px 30px 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;

  &-card{
    background-color: white;
    border-radius: 16px;
    padding: 20px;

  }
}

.recemethod__bottom{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 570px;
  width: 50%;
  padding: 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;
}