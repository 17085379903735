@import "../../../../global.scss";

.paylink{
  background-color: #fafafa;
  border-radius: 16px;
  min-width: 570px;
  width: 50%;
  padding: 20px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;
}