$white: #ffffff;
$black: #000000;

// main colors
$primary-light:#e9f2ff;
$primary: #acd7fa;
$primary-dark: #8ecce7;
// $primary: #74aaf7;

// $secondary: #1a2c5b;
// $secondary: #74aaf7;

$secondary-light: #253f83;
// $secondary: #2175ea;

$secondary: #0c66e3;

// $secondary: #0a66c2; // old
$secondary-dark: #0f1933;

// $secondary: #0866fa;
// $secondary-dark: #0866ff;

$purple-light: #e9e4ff;
$purple: #6E5DC6;

// flags
$success: #2e7d32;
$error: #d32f2f;
$warning: #ed6c02;

// text
$black-text: #000000;
// $main-text: #484848;
$main-text: #00000099;
$sub-text: #616161;