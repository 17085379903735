@import "../../global.scss";

.auth{
  height: 100vh;
  padding-top: 80px;
}

.auth__top{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  padding: 15px 0px;

  // background-color: $primary-light;
  // border-bottom: 1px solid $secondary; 
}


.auth__content{
  width: 100%;
  height: 60vh;

  // background-color: $primary-light;
  border-radius: 16px;
  // border: 1px solid $secondary; 

  &-step{
    margin: auto;
    margin-top: 20px;
    width: 400px;
    height: 300px;
  }

  &-otp{
    width: 100%;
    margin: 20px 0px;
    // padding: 20px 60px;
    display: flex;
    justify-content: space-evenly;
  
    &-input{
      height: 52px;
      min-width: 52px;
      border-radius: 10px;
      border: 1px solid;
      font-size: 22px;
      font-family: 'Urbanist', sans-serif;
      background-color: $white;
    }
  }
}

.auth__text{
  overflow: hidden;
  height: 0px; 
  color: $primary-dark!important;
  text-align: center;
}
