@import "../../global.scss";

.makepayment{
  
  &__top{
    min-height: 56px;
    padding: 5px 0px;
  }

  &__bottom{
    height: calc(100vh - 76.79px);
    gap: 40px;
  }
}

.makepayment{
  &__bank{
    width: 100%;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    border: 3px solid white;
    cursor: pointer;

    &-selected{
      border: 3px solid $secondary-light;
    }
  }

  &__bank:hover{
    box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 8px;
  }

  &__bank-disabled{
    width: 100%;
    background-color: #fff4f4;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    border: 3px solid #fff4f4;
  }
}